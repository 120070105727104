export enum AgentConnectionWithMerchant {
  inPerson = 'In-person',
  inboundLead = 'Inbound Lead',
  webLead = 'Web Lead',
  referral = 'Referral',
  coldCall = 'Cold Call',
}

export const getAgentConnectionWithMerchantFull = (
  agentConnectionWithMerchant: AgentConnectionWithMerchant
) => {
  switch (agentConnectionWithMerchant) {
    case AgentConnectionWithMerchant.inPerson:
      return 'In-person (I physically went to the location)';
    case AgentConnectionWithMerchant.inboundLead:
      return 'Inbound Lead (I was contacted by the merchant over the phone)';
    case AgentConnectionWithMerchant.webLead:
      return 'Web Lead (I was contacted by the merchant via online)';
    case AgentConnectionWithMerchant.referral:
      return 'Referral (I was referred by a trusted source)';
    case AgentConnectionWithMerchant.coldCall:
      return 'Cold Call (I reached out to this merchant)';
  }
};
