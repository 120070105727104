import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Badge,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {SxProps} from '@mui/system';
import {useMemo} from 'react';
import {matchPath} from 'react-router';
import {NavLink} from 'react-router-dom';
import {BadgeCounters, NavMenuLink, NavMenuParentLink} from './NavMenuTypes';

const PREFIX = 'NavMenuItem';
const navMenuItemClasses = {
  activeLink: `${PREFIX}-active`,
  icon: `${PREFIX}-icon`,
};

type Props = {
  link: NavMenuParentLink;
  expandedLink: string;
  onExpand: (name: string) => void;
  isItemVisible: (childLink: NavMenuLink) => boolean;
  badgeCounters?: BadgeCounters;
};

export const NavMenuItem = ({
  link,
  expandedLink,
  onExpand,
  isItemVisible,
  badgeCounters,
}: Props) => {
  const {name, icon, children, badgeCounter, disabled, redirectToFirstChild, exact} = link;
  const isExpanded = name === expandedLink;

  const isParentLinkActive: NavMenuParentLink['isActive'] = (match, location) => {
    const {children = []} = link;

    if (match) {
      return true;
    }

    const childRoutes = children.map(child => child.route);

    return childRoutes.some(route => matchPath(location.pathname, route));
  };

  const getLinkProps = (link: NavMenuLink) => {
    if (link.onClick) {
      return {onClick: link.onClick, component: Button};
    }
    const isExternalResource = link.route.startsWith('https://');
    const to = isExternalResource ? {pathname: link.route} : link.route;
    const target = isExternalResource ? '_blank' : '_self';

    return {
      component: NavLink,
      to: to,
      target: target,
      activeClassName: navMenuItemClasses.activeLink,
      exact,
      isActive: link.isActive,
    };
  };

  const getParentLinkProps = () => {
    const linkProps = getLinkProps(link);

    if (link.onClick) {
      return linkProps;
    }

    let to = link.route;
    if (children && redirectToFirstChild) {
      const firstVisibleChildLink = children.find(child => isItemVisible(child));
      if (firstVisibleChildLink) {
        to = firstVisibleChildLink.route;
      }
    }

    return {
      ...linkProps,
      to,
      isActive: link.children ? link.isActive ?? isParentLinkActive : link.isActive,
    };
  };

  const getListItemSX = (nested?: boolean): SxProps<Theme> => [
    {
      color: '#4d6575',
      borderRadius: 40,
      pl: 2,
      cursor: 'pointer',
      textTransform: 'none',
      '&:hover,&:focus': {
        color: '#1c252c',
        backgroundColor: '#f5fafc',
        [`& .${navMenuItemClasses.icon} svg`]: {
          fill: '#1c252c',
        },
      },
      [`&.${navMenuItemClasses.activeLink}`]: {
        color: theme => theme.palette.primary.main,
        backgroundColor: '#f5fafc',
        borderRadius: 40,
        [`& .${navMenuItemClasses.icon} svg`]: {
          fill: theme => theme.palette.primary.main,
        },
      },
    },
    Boolean(nested) && {
      pl: 4,
    },
  ];

  const availableChildren = useMemo(() => {
    if (!children) {
      return null;
    }

    return children.filter(isItemVisible);
  }, [children]);

  return (
    <>
      <ListItem disabled={disabled} {...getParentLinkProps()} sx={getListItemSX()}>
        {icon && (
          <ListItemIcon
            className={navMenuItemClasses.icon}
            sx={{minWidth: 'auto', mr: 2, '& svg': {fill: '#b2c2cd'}}}
          >
            {icon}
          </ListItemIcon>
        )}
        {badgeCounter && badgeCounters?.[badgeCounter] ? (
          <Badge
            badgeContent={badgeCounters?.[badgeCounter]}
            max={999}
            color="error"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <ListItemText>{name}</ListItemText>
          </Badge>
        ) : (
          <ListItemText>{name}</ListItemText>
        )}
        {!!availableChildren?.length && (
          <ListItemSecondaryAction>
            <IconButton onClick={() => onExpand(name)} size="large">
              <ExpandMoreIcon
                sx={{
                  transform: `rotate(${name === expandedLink ? 180 : 0}deg)`,
                  transition: 'transform 0.5s ease-in-out',
                }}
              />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>

      {!!availableChildren?.length && (
        <List
          component="div"
          disablePadding
          sx={[
            {
              transition: 'max-height 0.5s ease-in-out',
              overflow: 'hidden',
            },
            {maxHeight: isExpanded ? '200vh' : 0},
          ]}
        >
          {availableChildren.map(
            child =>
              isItemVisible(child) && (
                <ListItem
                  key={child.name}
                  disabled={child.disabled}
                  sx={getListItemSX(true)}
                  tabIndex={isExpanded ? 0 : -1}
                  {...getLinkProps(child)}
                >
                  {child.icon && (
                    <ListItemIcon
                      className={navMenuItemClasses.icon}
                      sx={{'& svg': {fill: '#b2c2cd'}}}
                    >
                      {child.icon}
                    </ListItemIcon>
                  )}
                  {child.badgeCounter && badgeCounters?.[child.badgeCounter] ? (
                    <Badge
                      badgeContent={badgeCounters[child.badgeCounter]}
                      max={999}
                      color="error"
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      sx={{
                        flex: '1 1 auto',
                      }}
                    >
                      <ListItemText primary={child.name} />
                    </Badge>
                  ) : (
                    <ListItemText primary={child.name} />
                  )}
                </ListItem>
              )
          )}
        </List>
      )}
    </>
  );
};
