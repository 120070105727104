import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {IconButton, Link, Typography, useMediaQuery, useTheme} from '@mui/material';
import {GroupRole, useAgent, useAgentScheduleA} from '@ozark/common';
import {AgentScheduleA, Loading, MerchantSupportModel, Title} from '@ozark/common/components';
import {useUserInfo} from '@ozark/common/hooks/useUserInfo';
import {useNotification} from '@ozark/crm/src/hooks/useNotification';
import {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import {AGENTS} from '../../constants/routes';
import {useStore} from '../../store/helpers';
import {Tab, Tabs} from '../Tabs';
import {AgentEdit} from './Edit';
import {GroupScheduleA} from './GroupScheduleA';

enum AgentTab {
  AgentInformation,
  MerchantSupportModel,
  ScheduleA,
  GroupScheduleA,
}

const DEFAULT_TAB = AgentTab.AgentInformation;

const Agent = () => {
  const {authProfile, isUserAdmin, isUserMember, isUserGroupAdmin, group} = useStore();
  const {isAgent, userGroupId, uid} = useUserInfo();
  const history = useHistory();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('md'));
  const showNotification = useNotification();
  const [tab, setTab] = useState(DEFAULT_TAB);
  const {id} = useParams<{id: string}>();
  const {
    document: {data: agent},
    set: setAgent,
  } = useAgent(id);

  const {document: scheduleA, addNewVersion: setScheduleA} = useAgentScheduleA(id);

  const isAdmin = isUserAdmin();
  const isMember = isUserMember();
  const isGroupAdmin = isUserGroupAdmin();

  const isGroupOwner = authProfile.data?.isGroupOwner;
  const isSubAgentOfCurrentUser = !!agent && agent.masterUid === authProfile.data?.id;
  const isSubAgent = !!agent && !!agent.masterUid;

  const isOwnAgentProfile = isAgent && !!uid && !!id && id === uid;

  const isSameGroup = agent?.group?.id === userGroupId;

  const agentAdminScheduleAEditable = !isOwnAgentProfile && isGroupAdmin && isSameGroup;

  const agentMemberScheduleAEditable =
    !isOwnAgentProfile && !isGroupAdmin && isSubAgentOfCurrentUser && isSameGroup;

  const canEditScheduleA = agentAdminScheduleAEditable || agentMemberScheduleAEditable;

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    if (query.has('tab')) {
      const newTab = Number(query.get('tab'));
      setTab(newTab || DEFAULT_TAB);
    }
  }, [history.location.search]);

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: number) => {
    setTab(newTab);
  };

  const setVisibility = (value: boolean) => {
    setAgent({
      isScheduleAVisible: value,
    })
      .then(() => {
        const message = `The Schedule A was made ${value ? 'visible for' : 'hidden from'} ${
          agent?.firstName
        } ${agent?.lastName}`;
        showNotification('success', message);
      })
      .catch((err: any) => {
        console.error(`failed to update the agent. ${err.toString()}`);
        showNotification('error', (err as Error).message);
      });
  };

  const isAgentMember = !!agent && agent.role === GroupRole.member;

  if (!agent || authProfile.promised) {
    return <Loading />;
  }

  return (
    <div>
      <Title
        breadcrumbs={
          smDown
            ? [
                <IconButton onClick={() => history.goBack()} size="large">
                  <ChevronLeftIcon />
                </IconButton>,
              ]
            : [
                <Link component="button" variant="body1" onClick={() => history.push(AGENTS)}>
                  Agents
                </Link>,
                <Typography variant="body1">{`${agent.firstName} ${agent.lastName}`}</Typography>,
              ]
        }
      >
        <Tabs value={tab} onChange={handleTabChange} centered>
          <Tab label="Agent Information" value={AgentTab.AgentInformation} />
          <Tab label="Merchant Support Model" value={AgentTab.MerchantSupportModel} />

          {/*make tab visible to agent admins and members who is allowed to see his schedule a*/}
          {(isUserGroupAdmin() || agent?.isScheduleAVisible) && (
            <Tab label="Schedule A" value={AgentTab.ScheduleA} disabled={scheduleA.promised} />
          )}

          {isAdmin && (
            <Tab label="Group Schedule A" value={AgentTab.GroupScheduleA} disabled={!agent} />
          )}
        </Tabs>
      </Title>

      {tab === AgentTab.AgentInformation && (
        <AgentEdit agentId={agent.id} isAuthUserAdmin={isAdmin} />
      )}
      {tab === AgentTab.MerchantSupportModel && (
        <MerchantSupportModel
          agentId={agent.id}
          isReadOnly={!isOwnAgentProfile && !isGroupAdmin && !isSubAgentOfCurrentUser}
          merchantSupportModel={agent.merchantSupportModel}
        />
      )}

      {tab === AgentTab.ScheduleA &&
        !scheduleA.promised &&
        (isUserGroupAdmin() || agent.isScheduleAVisible) && (
          <AgentScheduleA
            document={scheduleA.data}
            readonly={!canEditScheduleA}
            parentScheduleAParams={
              isSubAgent
                ? {
                    id: agent.masterUid,
                    type: 'agent',
                  }
                : isGroupOwner || isGroupAdmin
                ? {
                    id: agent.group.id,
                    type: 'group',
                  }
                : undefined
            }
            set={setScheduleA}
            setVisibility={setVisibility}
            applyDefaults={isSubAgentOfCurrentUser || isGroupOwner || isGroupAdmin}
            riskTogglesEnabled={false}
            displayCalculationMethod={isGroupAdmin}
            disableAgentCalculationMethod={
              isOwnAgentProfile ||
              !group.data?.applicationSettings?.allowAgentsUpdateResidualCalculation
            }
            canEnableSplitNegativeResiduals={
              (isGroupOwner || isGroupAdmin) &&
              group.data?.applicationSettings?.allowSplitNegativeResiduals
            }
            agent={agent}
            canCopyParentScheduleA={isAdmin}
            canCopyGroupAgentsScheduleA={isAdmin && isSameGroup}
            canCopySubAgentsScheduleA={isMember && isSubAgentOfCurrentUser}
            canManageVisibility={isAdmin && isSameGroup && isAgentMember}
          />
        )}
      {tab === AgentTab.GroupScheduleA && isAdmin && <GroupScheduleA groupId={agent.group.id} />}
    </div>
  );
};

export default Agent;
