import {yupResolver} from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import {
  Collections,
  emptyStringToNull,
  Firebase,
  IMerchantSupportModel,
  useNotification,
  useUserInfo,
} from '@ozark/common';
import PhoneNumber from 'awesome-phonenumber';
import {useEffect, useState} from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {SwitchMerchantSupportModel} from './Switch';

const FIELD_NAME = 'merchantSupportModel';

const emailRegex = new RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const commaSeparatedEmailsRegex = new RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(,[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*)*$/
);

const MerchantSupportModelValidationSchema = yup
  .object({
    customerPhoneNumber: yup
      .string()
      .transform(emptyStringToNull)
      .nullable(true)
      .transform(value => {
        const phone = value ? new PhoneNumber(value, 'US').getNumber('e164') ?? value : value;
        return phone;
      })
      .matches(/^\+1[2-9]{1}[0-9]{9}$/, 'Enter a valid phone number in e164 format (+18002333333)'),

    agentSupportEmail: yup
      .string()
      .transform(emptyStringToNull)
      .nullable(true)
      .matches(emailRegex, 'Enter a valid email'),

    underwritingAgentNotificationsEmail: yup
      .string()
      .transform(emptyStringToNull)
      .nullable(true)
      .matches(commaSeparatedEmailsRegex, 'Enter a valid email'),

    approvalAgentNotificationsEmail: yup
      .string()
      .transform(emptyStringToNull)
      .nullable(true)
      .matches(commaSeparatedEmailsRegex, 'Enter a valid email'),

    ticketAgentNotificationsEmail: yup
      .string()
      .transform(emptyStringToNull)
      .nullable(true)
      .matches(commaSeparatedEmailsRegex, 'Enter a valid email'),

    disputesAgentNotificationsEmail: yup
      .string()
      .transform(emptyStringToNull)
      .nullable(true)
      .matches(commaSeparatedEmailsRegex, 'Enter a valid email'),
  })
  .required();

interface MerchantSupportModelProps {
  agentId: string;
  merchantSupportModel?: IMerchantSupportModel;
  isReadOnly?: boolean;
}

export const MerchantSupportModel: React.FC<MerchantSupportModelProps> = ({
  agentId,
  merchantSupportModel,
  isReadOnly = false,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const showNotification = useNotification();
  const {isErpUser} = useUserInfo();

  const {
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<IMerchantSupportModel>({resolver: yupResolver(MerchantSupportModelValidationSchema)});

  const onSubmit: SubmitHandler<IMerchantSupportModel> = async data => {
    try {
      setIsSaving(true);
      await Firebase.firestore
        .collection(Collections.agents)
        .doc(agentId)
        .update({[FIELD_NAME]: data});
      showNotification('success', 'Merchant support model successfully saved');
    } catch (err) {
      console.error(err);
      showNotification('error', 'Merchant support model save failed');
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (!merchantSupportModel) {
      return;
    }

    reset(merchantSupportModel);
  }, [merchantSupportModel, reset]);

  return (
    <Paper
      sx={{
        mt: 2,
        px: 2,
        py: 3,
      }}
    >
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': {width: '100%'},
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Controller
              name="customerPhoneNumber"
              control={control}
              defaultValue=""
              render={({field}) => (
                <TextField
                  {...field}
                  disabled={isReadOnly}
                  type="tel"
                  label="Customer Support Phone Number"
                  error={Boolean(errors.customerPhoneNumber)}
                  placeholder="+18002333333"
                  helperText={
                    errors.customerPhoneNumber?.message ??
                    'Merchants will be directed to this phone number if they need assistance'
                  }
                />
              )}
            />
          </Grid>

          <Grid item xs={12}></Grid>

          <Grid item xs={4}>
            <Controller
              name="agentSupportEmail"
              control={control}
              defaultValue=""
              render={({field}) => (
                <TextField
                  disabled={isReadOnly}
                  type="email"
                  label="Agent Support Email"
                  {...field}
                  error={Boolean(errors.agentSupportEmail)}
                  helperText={
                    errors.agentSupportEmail?.message ??
                    'Merchants will be directed to this email if they need assistance'
                  }
                />
              )}
            />
          </Grid>

          {isErpUser && (
            <Grid item xs={12}>
              <SwitchMerchantSupportModel
                isReadOnly={isReadOnly}
                control={control}
                name="whiteLabelPhoneNumberHostedByLuqra"
                label="White Label Phone Number Hosted by Luqra"
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <SwitchMerchantSupportModel
              isReadOnly={isReadOnly}
              control={control}
              name="riskCommunicationDirectToMerchant"
              label="Risk Communication Direct to Merchant"
            />
          </Grid>

          <Grid item xs={12}>
            <SwitchMerchantSupportModel
              isReadOnly={isReadOnly}
              control={control}
              name="supportCommunicationDirectToMerchant"
              label="Support Communication Direct to Merchant"
            />
          </Grid>

          <Box component="fieldset" sx={{margin: 2}}>
            <legend>Agent Notifications Setting</legend>

            <Box
              sx={{
                fontSize: '14px',
                fontStyle: 'italic',
                marginBottom: '1em',
              }}
            >
              Agent email will be used by default
            </Box>

            <Box
              sx={{
                fontSize: '14px',
                fontStyle: 'italic',
                marginBottom: '2em',
              }}
            >
              Multiple emails separated by commas are allowed
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="underwritingAgentNotificationsEmail"
                  control={control}
                  defaultValue=""
                  render={({field}) => (
                    <TextField
                      disabled={isReadOnly}
                      type="email"
                      label="Underwriting Agent Notifications Email"
                      {...field}
                      error={Boolean(errors.underwritingAgentNotificationsEmail)}
                      helperText={errors.underwritingAgentNotificationsEmail?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="approvalAgentNotificationsEmail"
                  control={control}
                  defaultValue=""
                  render={({field}) => (
                    <TextField
                      disabled={isReadOnly}
                      type="email"
                      label="Approval Agent Notifications Email"
                      {...field}
                      error={Boolean(errors.approvalAgentNotificationsEmail)}
                      helperText={errors.approvalAgentNotificationsEmail?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="ticketAgentNotificationsEmail"
                  control={control}
                  defaultValue=""
                  render={({field}) => (
                    <TextField
                      disabled={isReadOnly}
                      type="email"
                      label="Ticket Agent Notifications Email"
                      {...field}
                      error={Boolean(errors.ticketAgentNotificationsEmail)}
                      helperText={errors.ticketAgentNotificationsEmail?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="disputesAgentNotificationsEmail"
                  control={control}
                  defaultValue=""
                  render={({field}) => (
                    <TextField
                      disabled={isReadOnly}
                      type="email"
                      label="Disputes Agent Notifications Email"
                      {...field}
                      error={Boolean(errors.disputesAgentNotificationsEmail)}
                      helperText={errors.disputesAgentNotificationsEmail?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Box textAlign="right" pt={2}>
          <Button type="submit" variant="contained" disabled={isSaving || isReadOnly}>
            Save
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};
