import ClearIcon from '@mui/icons-material/Clear';
import {IconButton, MenuItem, TextField} from '@mui/material';
import {useSelectSearch, VAL_FALSE, VAL_TRUE} from '@ozark/common';
import {ReactNode} from 'react';
import {Controller} from 'react-hook-form';
import {getErrorMessage} from '../../util';

interface Props {
  name: string;
  label: string;
  defaultValue?: string | number | boolean;
  required?: boolean;
  errors: any;
  control: any;
  options?: string[] | {[_: string]: string | number} | null | undefined;
  yesOrNo?: boolean | null;
  displayValue?: boolean | null;
  onChangeSuccess?: (value: string) => void;
  disableErrorText?: boolean;
  fullWidth?: boolean;
  displayedDeprecatedValue?: string | number;
  compareFn?: (a: any, b: any) => number;
  onClear?: () => void;
  renderValue?: (value: unknown) => ReactNode;
  [others: string]: any;
}

export const Select = ({
  name,
  label,
  defaultValue,
  required,
  errors,
  control,
  options,
  yesOrNo,
  displayValue,
  children,
  onChangeSuccess,
  onClear,
  disableErrorText = false,
  fullWidth = true,
  displayedDeprecatedValue,
  compareFn,
  renderValue,
  ...other
}: Props) => {
  const selectSearch = useSelectSearch();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ?? ''}
      rules={{required: required}}
      render={({field}) => (
        <TextField
          variant="outlined"
          margin="normal"
          required={required}
          fullWidth={fullWidth}
          id={name}
          label={label}
          value={displayedDeprecatedValue ? displayedDeprecatedValue : field.value ?? ''}
          data-test={name}
          inputRef={field.ref}
          InputProps={{
            endAdornment: onClear ? (
              <IconButton
                sx={{visibility: field.value ? 'visible' : 'hidden', mr: 2}}
                onClick={onClear}
              >
                <ClearIcon />
              </IconButton>
            ) : null,
          }}
          onChange={e => {
            field.onChange(e.target.value);
            onChangeSuccess?.(e.target.value);
          }}
          onKeyDown={e => {
            selectSearch.onKeyDown(
              e,
              field.value,
              newVal => {
                field.onChange(newVal);
                onChangeSuccess?.(newVal);
              },
              yesOrNo,
              options,
              !!compareFn,
              children
            );
          }}
          error={Boolean(getErrorMessage(name, errors))}
          helperText={disableErrorText ? null : getErrorMessage(name, errors)?.message}
          SelectProps={{
            renderValue: renderValue,
          }}
          select
          {...other}
        >
          {yesOrNo && [
            <MenuItem key={`${name}-true`} value={VAL_TRUE.value}>
              {VAL_TRUE.text}
            </MenuItem>,
            <MenuItem key={`${name}-false`} value={VAL_FALSE.value}>
              {VAL_FALSE.text}
            </MenuItem>,
          ]}
          {!yesOrNo &&
            options &&
            Array.isArray(options) &&
            (compareFn
              ? options.sort(compareFn).map(o => (
                  <MenuItem key={o} value={o}>
                    {o}
                  </MenuItem>
                ))
              : options.sortAndMap(e => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                )))}
          {!yesOrNo &&
            options &&
            !displayValue &&
            !Array.isArray(options) &&
            Object.keys(options).sortAndMap(e => (
              <MenuItem key={e} value={options[e]}>
                {e}
              </MenuItem>
            ))}

          {!yesOrNo &&
            options &&
            displayValue &&
            !Array.isArray(options) &&
            (compareFn
              ? Object.entries(options)
                  .sort(compareFn)
                  .map(([k, v]) => (
                    <MenuItem key={v} value={k}>
                      {v}
                    </MenuItem>
                  ))
              : Object.entries(options).sortAndMap(([k, v]) => (
                  <MenuItem key={v} value={k}>
                    {v}
                  </MenuItem>
                )))}
          {children}
        </TextField>
      )}
    />
  );
};
