import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {isEmpty} from '@s-libs/micro-dash';
import debounceRender from 'react-debounce-render';
import {BoxCentered, Loading, MidSelect} from '..';
import {useMidsContainer} from '../../store';
import {SearchInput} from '../SearchInput';
import {ButtonExportToCsv} from './ButtonExportToCsv';
import {ButtonFilters} from './ButtonFilters';
import {ButtonFiltersRow} from './ButtonFiltersRow';
import {MerchantsActivityTable} from './MerchantsActivityTable';
import {MerchantsActivityTitle} from './MerchantsActivityTitle';
import {useMerchantsInfoAndActivity} from './useMerchantsInfoAndActivity';

type Props = {
  siteJumpAsUser?: (user: {email?: string; uid?: string}) => Promise<void>;
};

const DebouncedMerchantsActivityTable = debounceRender(MerchantsActivityTable, 300);

export const MerchantsActivity = ({siteJumpAsUser}: Props) => {
  const {
    loading,
    tableConfig,
    filters,
    search,
    setFilters,
    setSearch,
    setMid,
    setSearchCriteria,
    fetchAllActivations,
  } = useMerchantsInfoAndActivity();
  const {mids, selectedMid, handleSelectMid} = useMidsContainer();
  const biggerThan1350 = useMediaQuery('(min-width:1350px)');
  const biggerThan1450 = useMediaQuery('(min-width:1450px)');

  const onTextChange = (value: string) => {
    setSearch(value);
  };

  const handleMidChange = (mid: string) => {
    handleSelectMid(mid);
    setMid(mid !== 'all' ? mid : '');
  };

  if (mids.promised || !mids.data) return <Loading />;

  return (
    <div>
      <MerchantsActivityTitle>
        <ButtonExportToCsv
          hasData={Boolean(tableConfig.data.length)}
          fetchData={fetchAllActivations}
        />
        {biggerThan1350 && (
          <>
            <Divider orientation="vertical" sx={{m: 2}} flexItem />
            <SearchInput
              disableExtendOnFocus={!biggerThan1450}
              widthOnFocus="20ch"
              filterText={search}
              onTextChange={onTextChange}
            />
          </>
        )}
        <Divider orientation="vertical" sx={{m: 2}} flexItem />
        <Typography>MID:</Typography>&emsp;
        <MidSelect
          selectedMid={selectedMid}
          handleSelectMid={handleMidChange}
          mids={{
            promised: mids.promised,
            data: mids.data,
          }}
        />
        <Divider orientation="vertical" sx={{m: 2}} flexItem />
        <ButtonFilters filters={filters} setFilters={setFilters} />
      </MerchantsActivityTitle>
      <Box
        sx={{
          width: '100%',
          justifyContent: 'flex-start',
          display: biggerThan1350 ? 'none' : 'block',
          marginBottom: theme => theme.spacing(2),
        }}
      >
        <SearchInput inputSmallWidth="100%" filterText={search} onTextChange={onTextChange} />
      </Box>
      <Grid container spacing={2} direction="row" alignItems="stretch">
        <Grid item xs={12}>
          <Stack direction="row">
            <ButtonFiltersRow filters={filters} setFilters={setFilters} />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {!loading && tableConfig && !isEmpty(tableConfig.data) ? (
            <DebouncedMerchantsActivityTable
              tableConfig={tableConfig}
              handlePageChange={setSearchCriteria}
              siteJumpAsUser={siteJumpAsUser}
            />
          ) : null}
        </Grid>
      </Grid>
      {!loading && tableConfig && isEmpty(tableConfig.data) && (
        <BoxCentered height="50vh">No merchants found.</BoxCentered>
      )}
      {loading && (
        <div>
          <BoxCentered height="50vh">
            <CircularProgress />
          </BoxCentered>
        </div>
      )}
    </div>
  );
};
