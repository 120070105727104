import {format, utcToZonedTime} from 'date-fns-tz';
import {Authorization} from '../../../express/private/types';
import {getEntryModeByCode} from '../../../express/private/types/Authorizations';
import {DATETIME_FORMAT} from '../../DateUtils';
import {ReportExportColumn} from '../ReportExportColumn';

export const MessageReasonCodes: {[_: string]: string} = {
  '2501': 'Transaction voided by customer (code 2502)',
  '2502': 'Transaction has not completed (Request timed out or terminal malfunctioned)',
  '2503': 'No confirmation from the point of sale',
  '2504': 'POS partial reversal',
  '2516': 'Premature chip card removal (after online request sent, before response received)',
  '2517': 'Chip declined transaction after online issuer approved',
  '2001': 'Transaction voided by customer (code 2001)',
  '2002': 'Wrong Amount',
  '2003': 'Partial Return',
  '2007': 'Debit or Credit Adjustment - no previous transaction (code 2007)',
  '2009': 'Debit or Credit Adjustment - no previous transaction (code 2009)',
  '2104': 'Acquirer Authorization Advice',
  '2140': 'Account Funding Transaction - debit or credit adjustment',
  '5201': 'EBT Voucher',
  '5206': 'Deferred Authorization',
  '3900': 'Incremental Authorization',
  '3901': 'Resubmission',
  '3902': 'Delayed Charges',
  '3903': 'Reauthorization',
  '3904': 'No Show',
};

export const AuthorizationResponses: {[_: string]: string} = {
  '00': 'Successful approval/completion',
  '01': 'Refer to card Issuer',
  '02': 'Refer to card Issuer, special condition',
  '03': 'Invalid merchant or service provider',
  '04': 'Pick up card',
  '05': 'Do not honor',
  '06': 'Error',
  '07': 'Pick up card, special condition (other than lost/stolen card)',
  '08': 'Honor with ID (Mastercard specific)',
  '10': 'Partial Authorization',
  '12': 'Invalid transaction',
  '13': 'Invalid amount',
  '14': 'Invalid account number (no such number)',
  '15': 'No such Issuer',
  '19': 'Re-enter transaction',
  '21': 'No action taken (unable to back out prior transaction)',
  '25': 'Unable to locate record in file, or account number is missing from the inquiry',
  '28': 'File is temporarily unavailable',
  '30': 'Format Error - Decline (Mastercard specific)',
  '34': 'Mastercard use only , Suspect Fraud (Used in reversal requests only)',
  '39': 'No credit account (Visa ePay)',
  '41': 'Pick up card (lost card)',
  '43': 'Pick up card (stolen card)',
  '46': 'Closed account',
  '51': 'Insufficient funds',
  '52': 'No checking account',
  '53': 'No savings account',
  '54': 'Expired card',
  '55': 'Incorrect PIN',
  '57': 'Transaction not permitted to cardholder',
  '58': 'Transaction not allowed at terminal',
  '59': 'Suspected fraud',
  '61': 'Exceeds approval amount limit',
  '62': 'Restricted card (invalid in region or country)',
  '63': 'Security violation (source is not correct issuer)',
  '65': 'Exceeds withdrawal frequency limit',
  '6P': 'Verification data failed',
  '75': 'Allowable number of PIN-entry tries exceeded',
  '76': 'Reversal: Unable to locate previous message (no match on Retrieval Reference number)',
  '77': 'Previous message located for a repeat or reversal, but repeat or reversal data are inconsistent with original message',
  '78': 'Blocked, first used transaction from new cardholder and card not properly unblocked',
  '79': 'Already reversed (by Switch)',
  '80': 'No financial Impact (Reversal for declined debit). Invalid date (for use in private label card transactions)',
  '81': 'PIN cryptographic error found (error found by the Visa security module during PIN decryption)',
  '82': 'Incorrect CVV Or Offline PIN authentication interrupted',
  '83': 'Unable to verify PIN',
  '84': 'Invalid Authorization Life Cycle - Decline (Mastercard). Duplicate Transaction Detected (Visa)',
  '85': 'No reason to decline a request for account number verification or address verification',
  '86': 'Cannot verify PIN',
  '87': 'Transaction type is not allowed',
  '91': 'Issuer unavailable or switch inoperative (STIP not applicable or available for this transaction)',
  '92': 'Destination cannot be found for routing',
  '93': 'Transaction cannot be completed; violation of law',
  '94': 'Duplicate Transmission Detected (Integrated Debit and Mastercard)',
  '96': 'System malfunction',
  B1: 'Surcharge amount not permitted on Visa cards or EBT Food Stamps',
  B2: 'Surcharge amount not supported by debit network issuer',
  CV: 'Card type verification error',
  EC: 'CID verification error',
  N0: 'Force STIP',
  N3: 'Cash service not available',
  N4: 'Cash request exceeds Issuer limit',
  N5: 'Ineligible for re-submission',
  N6: 'Decline',
  N7: 'Decline for CVV2 failure',
  N8: 'Transaction amount exceeds preauthorized approval amount',
  P2: 'Invalid biller Information',
  R3: 'Revocation of all Authorizations Order',
};

export const getAuthorizationFormattedTransactionDate = (
  transactionDate: string,
  timeZoneId: string
) => {
  const date = new Date(transactionDate);
  const localTime = utcToZonedTime(date, timeZoneId);
  return format(localTime, DATETIME_FORMAT);
};

export const getFormattedDisplayStatus = (row: Authorization): string => {
  if (row.messageReasonCode) {
    return MessageReasonCodes[row.messageReasonCode];
  }
  return row.authorizationResponse ? AuthorizationResponses[row.authorizationResponse] : 'Declined';
};

export type AuthorizationColumnNamesType =
  | 'cardType'
  | 'bin'
  | 'number'
  | 'authorizedAmountSigned'
  | 'approvalCode'
  | 'terminalNumber'
  | 'entryMode'
  | 'entryModeDescription'
  | 'transactionDate'
  | 'authorizationResponse';

export const AuthorizationColumnsConfigRecord: Record<
  AuthorizationColumnNamesType,
  ReportExportColumn<Authorization>
> = {
  cardType: {
    id: 'cardType',
    label: 'Card',
    export: true,
  },
  bin: {
    id: 'bin',
    label: 'BIN',
    export: true,
  },
  number: {
    id: 'number',
    label: 'Number',
    export: row => `${row.accountNumberFirst6}******${row.accountNumberLast4}`,
  },
  authorizedAmountSigned: {
    id: 'authorizedAmountSigned',
    label: 'Amount',
    export: true,
  },
  approvalCode: {
    id: 'approvalCode',
    label: 'Approval Code',
    export: true,
  },
  terminalNumber: {
    id: 'terminalNumber',
    label: 'TID',
    export: true,
  },
  entryMode: {
    id: 'entryMode',
    label: 'Entry Mode Code',
    export: true,
  },
  entryModeDescription: {
    id: 'entryModeDescription',
    label: 'Entry Mode',
    export: (row: Authorization) => getEntryModeByCode(row.entryMode),
  },
  transactionDate: {
    id: 'transactionDate',
    label: 'Date',
    export: row => getAuthorizationFormattedTransactionDate(row.transactionDate, 'PST'),
  },
  authorizationResponse: {
    id: 'authorizationResponse',
    label: 'Status',
    export: getFormattedDisplayStatus,
  },
};

export const AuthorizationExportColumnsConfig: ReportExportColumn<Authorization>[] = [
  {
    ...AuthorizationColumnsConfigRecord.cardType,
  },
  {
    ...AuthorizationColumnsConfigRecord.bin,
  },
  {
    ...AuthorizationColumnsConfigRecord.number,
  },
  {
    ...AuthorizationColumnsConfigRecord.authorizedAmountSigned,
  },
  {
    ...AuthorizationColumnsConfigRecord.approvalCode,
  },
  {
    ...AuthorizationColumnsConfigRecord.terminalNumber,
  },
  {
    ...AuthorizationColumnsConfigRecord.entryMode,
  },
  {
    ...AuthorizationColumnsConfigRecord.entryModeDescription,
  },
  {
    ...AuthorizationColumnsConfigRecord.transactionDate,
  },
  {
    ...AuthorizationColumnsConfigRecord.authorizationResponse,
  },
];
