import {Badge, Box} from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import {useNotificationsState} from './hooks/useNotificationsState';

export function FilterTabs() {
  const {setIsUnreadView, isUnreadView, unreadCount} = useNotificationsState();
  const [value, setValue] = React.useState(isUnreadView ? 0 : 1);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    setIsUnreadView(newValue === 0 ? true : false);
  };

  return (
    <Box onClick={e => e.stopPropagation()} borderBottom="1px solid #e8e8e8">
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label={<TabLabelWithBadge label="Unread" unreadCount={unreadCount} />} />
        <Tab label="All" />
      </Tabs>
    </Box>
  );
}

const TabLabelWithBadge = ({label, unreadCount}: {label: string; unreadCount: number}) => (
  <StyledBadge badgeContent={unreadCount} invisible={!unreadCount} color="error" max={999}>
    {label}
  </StyledBadge>
);

const StyledBadge = withStyles({
  badge: {
    right: -10,
  },
})(Badge);
