import {useAgentCalculationMethod} from './hooks/useAgentCalculationMethod';
import {ScheduleA} from './ScheduleA';
import {ScheduleAProps} from './types/ScheduleAProps';

type AgentScheduleAProps = ScheduleAProps;
export const AgentScheduleA = ({
  agent,
  document,
  parentScheduleAParams,
  set,
  setVisibility,
  applyDefaults = false,
  readonly = false,
  allowNegatives = false,
  riskTogglesEnabled = true,
  displayCalculationMethod = false,
  disableAgentCalculationMethod = false,
  canCopyParentScheduleA = false,
  canCopyGroupAgentsScheduleA = false,
  canCopySubAgentsScheduleA = false,
  canCopyAllAgentsScheduleA = false,
  canEnableSplitNegativeResiduals = false,
  canManageVisibility = false,
}: AgentScheduleAProps) => {
  const {disableSplitMethodChangeBasedOnApplicationResidualOverride} = useAgentCalculationMethod(
    agent?.id
  );
  return (
    <ScheduleA
      agent={agent}
      document={document}
      set={set}
      setVisibility={setVisibility}
      disableAgentCalculationMethod={
        disableSplitMethodChangeBasedOnApplicationResidualOverride || disableAgentCalculationMethod
      }
      allowNegatives={allowNegatives}
      applyDefaults={applyDefaults}
      displayCalculationMethod={displayCalculationMethod}
      parentScheduleAParams={parentScheduleAParams}
      readonly={readonly}
      riskTogglesEnabled={riskTogglesEnabled}
      canCopyParentScheduleA={canCopyParentScheduleA}
      canCopyGroupAgentsScheduleA={canCopyGroupAgentsScheduleA}
      canCopySubAgentsScheduleA={canCopySubAgentsScheduleA}
      canCopyAllAgentsScheduleA={canCopyAllAgentsScheduleA}
      canEnableSplitNegativeResiduals={canEnableSplitNegativeResiduals}
      canManageVisibility={canManageVisibility}
    />
  );
};
