import {
  ApplicationDisputeAdditionalEmail,
  ApplicationDisputeReason,
  ApplicationView,
  PendingReasonSettings,
  ReasonInputType,
  useCallable,
  useDisputeHistory,
  useDocumentsUploadLink,
} from '@ozark/common';
import camelcase from 'lodash/camelCase';
import {DisputeReason} from '../components';

export type DisputeEmailRecipient = {
  user: 'agent' | 'merchant';
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  isAgent: boolean;
};

type Props = {
  application: ApplicationView;
  disputeReasons: DisputeReason[];
  pendingReasonSettings: PendingReasonSettings[];
};

type disputeField = {
  key: string;
  value: null | string | string[] | boolean;
};

export const useDisputeEmails = ({application, disputeReasons, pendingReasonSettings}: Props) => {
  const {sendApplicationDispute} = useCallable();
  const {getDisputeReasons} = useDisputeHistory();
  const {documentsUploadLink} = useDocumentsUploadLink();

  const uploadButtonMarkup = async (
    fields: disputeField[],
    userId: string,
    email: string,
    name: string,
    user: 'agent' | 'merchant'
  ) => {
    // list of selected fields
    const fieldsForProcessing = fields.filter(x => x.value).map(x => x.key);
    // check if any document for upload by merchant
    const documentsForUpload = pendingReasonSettings
      .filter(x => fieldsForProcessing.includes(camelcase(x.name)))
      .map(x => x.document);

    if (documentsForUpload.length === 0) {
      return '';
    }

    const linkData = await documentsUploadLink(
      application.id,
      userId,
      email,
      name,
      user,
      fieldsForProcessing as string[]
    );

    return `<div class="dispute-reason">
      <table width="250"
        style="width:250px; background-color:${linkData?.groupHexColorPrimary}; border-radius:4px; margin-top:20px; margin-bottom:20px"
        border="0" cellspacing="0" cellpadding="0" align="center">
        <tr>
            <td class="em_white" height="42" align="center" valign="middle"
                style="font-family: Arial, sans-serif; font-size: 16px; color:${linkData?.groupHexColorTextPrimary}; font-weight:bold; height:42px;">
                <a href="${linkData?.portalUrl}" target="_blank" style="text-decoration:none; color:${linkData?.groupHexColorTextPrimary}; line-height:42px; display:block;">Respond</a>
            </td>
        </tr>
      </table>
    </div>`;
  };

  const fieldsMarkup = async (
    fields: ApplicationDisputeReason[],
    displaySelectValue: boolean
  ): Promise<string[]> => {
    return fields
      .map((field: ApplicationDisputeReason, idx: number) => {
        const disputeReason = disputeReasons.find(({name}) => name === field.key);
        const disputeReasonMarkup = `${disputeReason?.descriptionHtml}`;
        let fieldMarkup =
          typeof field.value === 'string' &&
          (displaySelectValue || disputeReason?.inputType !== ReasonInputType.Select)
            ? `<p class="dispute-text">${field.value}</p>`
            : Array.isArray(field.value) && field.value.every(v => typeof v === 'string')
            ? `<p class="dispute-text">${field.value.join(', ')}</p>`
            : '';

        return `<div class="dispute-reason">${disputeReasonMarkup}${fieldMarkup}</div>`;
      })
      .filter(field => field !== null) as string[];
  };

  const handleSendEmails = async (
    recipients: DisputeEmailRecipient[],
    fields: ApplicationDisputeReason[]
  ) => {
    try {
      const markupedFields = await fieldsMarkup(fields, false);
      const markupedFieldsForNote = await fieldsMarkup(fields, true);
      const emails: ApplicationDisputeAdditionalEmail[] = [];

      for (const r of recipients) {
        if (r.email.trim().length > 0) {
          const name = `${r.firstName ?? ''} ${r.lastName ?? ''}`.trim();
          const agentUploadButton = await uploadButtonMarkup(
            fields,
            r.userId,
            r.email, // TODO check if we need to use main agent's email due to docs upload issues while using comma separated emails from agent merchant support model
            name,
            r.user
          );
          emails.push({
            fields: markupedFields,
            to: [...r.email.split(',').map(x => x.trim())], // email can be comma separated: Merchant Support Model email
            content: {
              firstName: r.firstName,
              uploadButton: agentUploadButton,
            },
            isAgent: r.isAgent,
          });
        }
      }

      await sendApplicationDispute({
        applicationId: application.id,
        disposition: application.disposition,
        fields: markupedFields,
        fieldsForNote: markupedFieldsForNote,
        saveNotes: true,
        additionalEmails: emails,
      });
    } catch (err) {
      console.error('error sendApplicationDispute', err);
    }
  };

  const resendDisputeEmails = async (recipients: DisputeEmailRecipient[]) => {
    // load current dispute reasons
    const reasons = await getDisputeReasons(application.id, application.disposition);

    // send emails
    await handleSendEmails(recipients, reasons);
  };

  return {
    uploadButtonMarkup,
    fieldsMarkup,
    resendDisputeEmails,
  };
};
