import {Box, Button, Typography} from '@mui/material';
import {AgentView} from '@ozark/common';
import {AutoCompleteTextField, Loading, Title} from '@ozark/common/components';
import {sortBy} from 'lodash';
import {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {useStore} from '../../store/helpers';
import {AgentGrid} from './AgentsGrid';
import {CreateDialog} from './CreateDialog';

export const Agents = () => {
  const history = useHistory();
  const {getAuthorizedAgents, authProfile, group, isUserAdmin, isUserAgent, isUserMerchant} =
    useStore();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [authorizedAgents, setAuthorizedAgents] = useState<AgentView[]>();

  const [selectedAgent, setSelectedAgent] = useState<AgentView>();
  const [filteredAgents, setFilteredAgents] = useState<AgentView[]>([]);
  const [loadAgents, setLoadAgents] = useState(true);

  const loadAuthorizedAgents = useCallback(async () => {
    const agents = await getAuthorizedAgents();
    const activeAgents = agents?.filter(agent => agent.isActive && !agent.deleted) ?? [];
    const sortedAgents = sortBy(activeAgents, ['firstName', 'lastName']);
    setAuthorizedAgents(sortedAgents);
    setLoadAgents(false);
  }, [getAuthorizedAgents]);

  useEffect(() => {
    if (!loadAgents) {
      return;
    }
    loadAuthorizedAgents();
  }, [loadAgents, loadAuthorizedAgents]);

  useEffect(() => {
    loadAuthorizedAgents();
  }, [
    loadAuthorizedAgents,
    // Add subAgentUids as dependency for the case when current member agent creates sub-agent.
    // Once subAgentUids for the current agent is changed - refresh list
    authProfile?.data?.subAgentUids,
  ]);

  const handleAgentCreated = (uid: string) => {
    setCreateDialogOpen(false);
    history.push(`/agents/${uid}?tab=2`); // Redirect to Schedule A tab TODO: use named tabs (ERP also)
  };

  useEffect(() => {
    if (authorizedAgents?.length && selectedAgent) {
      setFilteredAgents([selectedAgent]);
    } else {
      setFilteredAgents(authorizedAgents ?? []);
    }
  }, [authorizedAgents, selectedAgent]);

  let enableAgentProfileCreation: boolean = false;
  if (isUserMerchant()) {
    enableAgentProfileCreation = false;
  } else if (isUserAgent() && isUserAdmin()) {
    enableAgentProfileCreation = true; // Group admin can always create new agents
  } else if (isUserAgent()) {
    enableAgentProfileCreation =
      !authProfile.data?.masterUid && // Sub-agents are not allowed to create new agents
      group.data?.applicationSettings?.enableAgentProfileCreation !== false && // If enableAgentProfileCreation is undefined - group member can still create new agents.
      authProfile?.data?.isScheduleAVisible; // Only agents with visible schedule A can create new agents
  }

  if (!authorizedAgents?.length) {
    return <Loading />;
  }

  return (
    <>
      <Box height="100%" display="flex" flexDirection="column">
        <Title breadcrumbs={[<Typography>Agents</Typography>]} />
        <Box display="flex" justifyContent="space-between" pb={1}>
          <Box>
            {enableAgentProfileCreation && (
              <Button onClick={() => setCreateDialogOpen(true)} variant="outlined">
                Create New Agent
              </Button>
            )}
          </Box>
          <Box>
            <AutoCompleteTextField
              placeholder="Filter by Name"
              options={authorizedAgents}
              selected={selectedAgent}
              setSelected={setSelectedAgent}
              getOptionLabel={(agent: AgentView) => {
                return `${agent.firstName ?? ''} ${agent.lastName ?? ''}`.trim();
              }}
              onItemSelect={(agent: AgentView | null) => setSelectedAgent(agent ?? undefined)}
            />
          </Box>
        </Box>
        <Box position="relative" flex={1}>
          {authorizedAgents && (
            <Box
              position="absolute"
              width="100%"
              height="100%"
              overflow="auto"
              borderTop={1}
              borderColor="rgba(224, 224, 224, 1)"
            >
              <AgentGrid agents={filteredAgents} />
            </Box>
          )}
        </Box>
      </Box>
      {createDialogOpen && (
        <CreateDialog
          onCancel={() => setCreateDialogOpen(false)}
          onAgentCreated={uid => handleAgentCreated(uid)}
        />
      )}
    </>
  );
};

export default Agents;
