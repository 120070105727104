import TagIcon from '@mui/icons-material/Tag';
import {Avatar, Badge, Box, Tooltip, Typography} from '@mui/material';
import {useWatch} from 'react-hook-form';
import {TicketTypeAndCategory} from '../../../..';
import {TextDetailTitle} from '../../common/TextDetailTitle';
import {FIELD_NAME_CATEGORY} from '../../constants/constants';

export const CategoryInfo = () => {
  const category: TicketTypeAndCategory | undefined = useWatch({name: FIELD_NAME_CATEGORY});

  if (!category) return <span>...</span>;

  const name = category.type?.name ?? '...';
  const description = category.category?.name ?? '';
  const avatar = (
    <Avatar sx={{width: 34, height: 34}}>
      <TagIcon />
    </Avatar>
  );
  return (
    <Box display="flex" alignItems="flex-start" flex={1}>
      {category?.category?.info && (
        <Tooltip title={category?.category?.info}>
          <Badge
            max={999}
            badgeContent="i"
            overlap="circular"
            color="primary"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            sx={{
              userSelect: 'none',
            }}
          >
            {avatar}
          </Badge>
        </Tooltip>
      )}
      {!category?.category?.info && avatar}
      <Box pl={1} maxWidth={135}>
        <TextDetailTitle>{name}</TextDetailTitle>
        {description && (
          <Typography variant="body2" color="secondary" component="div">
            <Box>{description ?? 'description'}</Box>
          </Typography>
        )}
      </Box>
    </Box>
  );
};
