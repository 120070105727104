import {Activation} from '@ozark/functions/src/functions/express/private/types';

export type MerchantAgentInfo = {
  group: string;
  agent: string;
};

export type MerchantInfoAndActivity = Activation;

export type MerchantsActivityTableConfig = {
  data: MerchantInfoAndActivity[];
  limit: number;
  offset: number;
  sort: [string, 'ASC' | 'DESC'][];
  totalCount: number;
};

export const getAgentsInfo = (agents: MerchantAgentInfo[]) =>
  agents.map(x => `${x.group} (${x.agent || 'No Assigned Agent'})`).join('; ');
