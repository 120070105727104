import GetAppIcon from '@mui/icons-material/GetApp';
import {Button} from '@mui/material';
import {ExportToCsv} from '@ozark/common';
import {Activation} from '@ozark/functions/src/functions/express/private/types';

type Props = {
  hasData: boolean;
  fetchData: () => Promise<Activation[]>;
};

export const ButtonExportToCsv = ({hasData, fetchData}: Props) => {
  const exportToCsv = async () => {
    if (!hasData) return;

    const options = {
      fieldSeparator: ',',
      filename: `activations`,
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Activations',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const data = await fetchData();

    const exportData = data.map(d => {
      const agents = d.agents.map(a => `${a.group} (${a.agent})`).join(' - ');

      return {
        ...d,
        agents,
      };
    });
    const exporter = new ExportToCsv(options);

    exporter.generateCsv(exportData);
  };

  return (
    <Button startIcon={<GetAppIcon />} disabled={!hasData} onClick={exportToCsv}>
      Export
    </Button>
  );
};
