import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import {Box, Button, Checkbox, Chip, Stack} from '@mui/material';
import {MAX_INVALID_MERCHANT_REG_ATTEMPTS} from '@ozark/functions/src/constants';
import PhoneNumber from 'awesome-phonenumber';
import {useMemo, useRef, useState} from 'react';
import {SendEmailDialog, Table} from '..';
import {Column} from '../../api/Column';
import {SearchCriteria} from '../../api/SearchCriteria';
import {withOverflowConfirmation} from '../../hocs';
import {useCallable, useNotification, useUserInfo} from '../../hooks';
import {getAgentsInfo, MerchantInfoAndActivity, MerchantsActivityTableConfig} from './types';

const UnlockMerchantButton = withOverflowConfirmation(Button);

const getTableColumns = (getActions: (row: MerchantInfoAndActivity) => JSX.Element) => {
  return [
    {
      id: 'mid',
      numeric: false,
      sortable: false,
      export: true,
      label: 'Merchant MID',
      selector: row => row.mid,
    },
    {
      id: 'merchant',
      numeric: false,
      sortable: false,
      export: true,
      label: 'Merchant DBA',
      selector: row => row.merchant,
    },
    {
      id: 'group',
      numeric: false,
      sortable: false,
      export: true,
      label: 'Group (Agent)',
      selector: row => getAgentsInfo(row.agents),
    },
    {
      id: 'email',
      numeric: false,
      sortable: false,
      export: true,
      label: 'Email',
      selector: row => row.email,
    },
    {
      id: 'phoneNumber',
      numeric: false,
      sortable: false,
      export: true,
      label: 'Phone Number',
      selector: row =>
        row.phoneNumber && !row.phoneNumber.includes('(')
          ? new PhoneNumber(row.phoneNumber, 'US').getNumber('national')
          : row.phoneNumber,
    },
    {
      id: 'isRegistered',
      sortable: false,
      export: true,
      label: 'Registered',
      selector: row => <Checkbox checked={row.isRegistered} disabled={true} color="primary" />,
    },
    {
      id: 'status',
      numeric: false,
      sortable: false,
      export: true,
      label: 'Status',
      selector: row => (
        <Stack spacing={1} direction="column" alignItems="flex-start">
          {row.processedActively && (
            <Chip
              size="small"
              icon={<CheckCircleOutlineOutlinedIcon />}
              label="actively processed last month"
              title="processing more then $10.00 in 30 days"
              color="success"
              variant="outlined"
            />
          )}
          {row.processingStopped && (
            <Chip
              size="small"
              icon={<ReportOutlinedIcon color="warning" />}
              label="stopped processing for 3 or more days"
              title="no sales for 3 consecutive days"
              variant="outlined"
              color="error"
            />
          )}
        </Stack>
      ),
    },
    {
      id: 'actions',
      numeric: true,
      sortable: false,
      export: false,
      selector: getActions,
    },
  ] as Column<MerchantInfoAndActivity>[];
};

type Props = {
  tableConfig: MerchantsActivityTableConfig;
  handlePageChange: (searchCriteria: SearchCriteria) => void;
  siteJumpAsUser?: (user: {email?: string; uid?: string}) => Promise<void>;
};

export const MerchantsActivityTable = ({tableConfig, siteJumpAsUser, handlePageChange}: Props) => {
  const [sendEmailDialogOpen, setSendEmailDialogOpen] = useState(false);
  const sendEmailTarget = useRef<MerchantInfoAndActivity>();
  const {isErpAdmin, isAgentAdmin} = useUserInfo();
  const {resetMerchantRegistrationAttempts} = useCallable();
  const showNotification = useNotification();

  const isMerchantRegistrationLocked = (row: MerchantInfoAndActivity) =>
    row.invalidMerchantRegAttempts >= MAX_INVALID_MERCHANT_REG_ATTEMPTS;

  const sendEmail = (row: MerchantInfoAndActivity) => {
    sendEmailTarget.current = row;
    setSendEmailDialogOpen(true);
  };

  const unlockMerchant = (applicationId: string) => {
    resetMerchantRegistrationAttempts({applicationId})
      .then(response => {
        if (response.status === 'ok') {
          showNotification('success', 'Merchant Password Reset Unlocked');
          return;
        }

        console.error('Unlock Merchant Error', response);
        showNotification('error', 'An error occurred while unlocking merchant');
      })
      .catch(error => {
        console.error('Unlock Merchant Error', error);
        showNotification('error', 'An error occurred while unlocking merchant');
      });
  };

  const columnsConfig = useMemo(() => {
    const getActions = (row: MerchantInfoAndActivity) => (
      <Box sx={{mr: -1, mb: -1}}>
        {siteJumpAsUser && row.merchantUid && (
          <Button
            variant="contained"
            onClick={() => siteJumpAsUser({uid: row.merchantUid ?? ''})}
            size="small"
            sx={{mr: 1, mb: 1}}
          >
            Jump to Portal
          </Button>
        )}
        <Button variant="contained" onClick={() => sendEmail(row)} size="small" sx={{mr: 1, mb: 1}}>
          Resend Activation Email
        </Button>
        {!row.isRegistered && (isErpAdmin || isAgentAdmin) && isMerchantRegistrationLocked(row) && (
          <UnlockMerchantButton
            size="small"
            wrapperSx={{display: 'inline-flex'}}
            sx={{lineHeight: 1.75, fontSize: '13px'}}
            id="unlock-merchant"
            variant="contained"
            primaryButtonColor="info"
            primaryButtonText="Unlock"
            secondaryButtonText="Cancel"
            title={`Are you sure you want to unlock?`}
            tooltip="Unlock Merchant"
            onClick={() => unlockMerchant(row.applicationId)}
          >
            Unlock Merchant Registration
          </UnlockMerchantButton>
        )}
      </Box>
    );

    return getTableColumns(getActions);
  }, [isErpAdmin, isAgentAdmin, siteJumpAsUser, unlockMerchant, sendEmail]);

  return (
    <>
      <Table
        columns={columnsConfig}
        data={tableConfig}
        getRowProps={row => ({
          sx: {
            backgroundColor: isMerchantRegistrationLocked(row)
              ? 'rgba(211, 47, 47, 0.1)'
              : 'transparent',
          },
        })}
        paginate
        useOffsetAsPage
        onRetrieveData={handlePageChange}
      />
      {sendEmailDialogOpen && sendEmailTarget.current && (
        <SendEmailDialog
          applicationId={sendEmailTarget.current.applicationId}
          initialEmail={sendEmailTarget.current.customerServiceEmail}
          onClose={() => setSendEmailDialogOpen(false)}
        />
      )}
    </>
  );
};
