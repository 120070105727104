import {useEffect, useMemo, useState} from 'react';
import {Filter} from '..';
import {SearchCriteria, useApiContainer} from '../..';
import {MerchantInfoAndActivity, MerchantsActivityTableConfig} from './types';

export function useMerchantsInfoAndActivity() {
  const apiClient = useApiContainer();
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [merchantsInfoAndActivity, setMerchantsInfoAndActivity] = useState<
    MerchantInfoAndActivity[]
  >([]);

  const [mid, setMid] = useState('');
  const [filters, setFilters] = useState<Filter>({});
  const [search, setSearch] = useState('');
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>({
    offset: 1,
    limit: 10,
    order: 'ASC',
    orderBy: 'merchant',
  });

  const fetchAllActivations = async () => {
    const result = await apiClient?.activations.getActivations(null, Object.values(filters), {
      search,
      mid,
      all: true,
    });

    return result?.activations ?? [];
  };

  const fetchActivations = async () => {
    setLoading(true);
    const result = await apiClient?.activations.getActivations(
      searchCriteria,
      Object.values(filters),
      {search, mid}
    );

    if (result?.activations) {
      setTotal(result.total);
      setMerchantsInfoAndActivity(result.activations);
    }
    setLoading(false);
  };

  const handlePageReset = () => {
    if (searchCriteria.offset === 1) {
      fetchActivations();
    } else {
      setSearchCriteria(prev => ({...prev, offset: 1}));
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(handlePageReset, 300);

    return () => clearTimeout(timeoutId);
  }, [search]);

  useEffect(() => {
    handlePageReset();
  }, [filters, mid]);

  useEffect(() => {
    fetchActivations();
  }, [searchCriteria]);

  const tableConfig: MerchantsActivityTableConfig = useMemo(
    () => ({
      data: merchantsInfoAndActivity,
      limit: searchCriteria.limit,
      offset: searchCriteria.offset,
      sort: [[searchCriteria.orderBy, searchCriteria.order as 'ASC' | 'DESC']],
      totalCount: total,
    }),
    [merchantsInfoAndActivity, searchCriteria, total]
  );

  return {
    loading,
    tableConfig,
    filters,
    search,
    setMid,
    setFilters,
    setSearch,
    setSearchCriteria,
    fetchAllActivations,
  };
}
