import {SearchOptions} from '@algolia/client-search';

export const DEFAULT_SEARCH_OPTIONS: SearchOptions = {
  analytics: false,
  attributesToRetrieve: ['*'],
  exactOnSingleWordQuery: 'word',
  hitsPerPage: 200,
  page: 0,
  typoTolerance: 'min',
};
