import {Badge} from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {useUserInfo} from '../../../hooks';
import {useTicketsAllAgentAdmin} from '../hooks/useTicketsAllAgentAdmin';
import {useTicketsAllAgentMember} from '../hooks/useTicketsAllAgentMember';
import {useTicketsAllErp} from '../hooks/useTicketsAllErp';
import {useTicketsAllMerchant} from '../hooks/useTicketsAllMerchant';
import {StatusFolderProps} from '../types';

export const TicketsListFolderAll = ({folder, isSelected}: StatusFolderProps) => {
  const {isMerchant, isAgentAdmin, isAgentMember} = useUserInfo();
  const {counter = 0} = folder.hasCounter
    ? isMerchant
      ? useTicketsAllMerchant(folder.statusSet, folder.filterKey)
      : isAgentAdmin
      ? useTicketsAllAgentAdmin(folder.statusSet, folder.filterKey)
      : isAgentMember
      ? useTicketsAllAgentMember(folder.statusSet, folder.filterKey)
      : useTicketsAllErp(folder.statusSet, folder.filterKey, true)
    : {counter: 0};

  const Icon = folder.icon;
  return (
    <ListItemButton selected={isSelected} sx={{pl: folder.indent ? 4 : 2}}>
      {folder.hasCounter && (
        <ListItemIcon>
          <Badge badgeContent={counter} max={99} color={isSelected ? 'primary' : 'default'}>
            {Icon && <Icon />}
          </Badge>
        </ListItemIcon>
      )}
      {!folder.hasCounter && <ListItemIcon>{Icon && <Icon />}</ListItemIcon>}
      <ListItemText
        primary={folder.folderName}
        primaryTypographyProps={{
          fontWeight: isSelected ? '500' : 'initial',
          color: isSelected ? 'black' : 'initial',
          fontStyle: folder.italic ? 'italic' : 'initial',
        }}
      />
    </ListItemButton>
  );
};
