import {GetActivationsResponse} from '@ozark/functions/src/functions/express/private/types/Activations';
import {ActiveFilter} from '../components';
import {toQuery} from '../util';
import {ApiBase} from './ApiBase';
import {SearchCriteria} from './SearchCriteria';

export class ActivationsApi extends ApiBase {
  getActivations = (
    searchCriteria: SearchCriteria | null,
    filters: ActiveFilter[],
    {search, mid, all}: {search?: string; mid?: string; all?: boolean}
  ) => {
    let queryStr = toQuery(searchCriteria, filters);

    if (search && search?.trim().length > 0) {
      queryStr += `&search=${search}`;
    }

    if (mid && mid?.trim().length > 0) {
      queryStr += `&mid=${mid}`;
    }

    if (all) {
      queryStr += `&all=true`;
    }

    return this.get<GetActivationsResponse>(`activations?${queryStr}`);
  };
}
